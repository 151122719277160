@import "styles/colors.less";
@import "styles/global.less";
/* autoprefixer: ignore next */
// Nav bar css start/

.main-routes-container {
  margin-bottom: 67px;
}

.navbar {
  display: flex;
  background-color: white;
  
  width: 100%;
  position: fixed;
  z-index: 1;
  justify-content: space-between;
  padding: 0.3rem;
  align-items: center;
  border-bottom: 1px solid #d9d0d0;
  .left-part {
    display: flex;
    flex: 1;
    background-color: white;
    z-index: 1;
    .logo {
      height: 32px;
      margin-left: 0.5rem;
    }
  }
  .center-part {
    flex: 1;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-name {
      font-size: 20px;
    }
  }
  .right-part {
    flex: 1;
    color: black;
    display: flex;
    /* autoprefixer: ignore next */
    align-items: end;
    /* autoprefixer: ignore next */
    justify-content: end;
  }
}

// On bigger screen
@media (min-width: 992px) {
  .menu {
    display: none;
  }
  .children-router {
    margin-left: 0px;
  }
  .left-part {
    .logo {
      margin-left: 0rem;
    }
  }
}

// Nav bar css end/

.children-router {
  margin-left: 200px;
}

@media (max-width: 992px) {
  .children-router {
    margin-left: 0px;
  }
}
