@font-face {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Bold";
  font-style: bolt;
  font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
  src: url("./assets/fonts/Lato-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Lato-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #414146;
}

// * {
//   font-family: "Lato-Regular";
// }

* {
  font-family: Lato, Helvetica, Arial, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pro-row {
  max-width: 1200px;
  margin: 0 auto;
}

/* app fonts responsive */
// @nice-blue: #5B83AD;

.app-section-title-responsive {
  font-size: 40px;
}
.app-section-subtitle-responsive {
  font-size: 16px;
}

// Small Devices
@media (max-width: 768px) {
  // Max width manje <= 768px
  .app-section-title-responsive {
    font-size: 24px;
  }
  .app-section-subtitle-responsive {
    font-size: 14px;
  }
  .hide-on-small-devices {
    display: none !important;
  }
}

// Large Devices
@media (min-width: 769px) {
  // min width manje >= 769px
  .app-section-title-responsive {
    font-size: 24px;
  }
}

// xs: `(max-width: 576px)`,
// sm: `(min-width: 576px)`,
// md: `(min-width: 768px)`,
// lg: `(min-width: 992px)`,
// xl: `(min-width: 1200px)`,
// xxl: `(min-width: 1400px)`
