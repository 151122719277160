.create-profile-with-steps {
  color: #414146;
  text-align: center;
  padding:50px;
  .title {
    font-weight: 600;
    color: #414146;
  }
  .create-profile-icon {
    font-size: 3rem;
    color: #28328c;
  }
  .create-profile-title {
    font-weight: 700;
  }
  .create-profile-subtitle {
    margin-top: 3px;
  }
}
