@brand: #044e8d;


// Sizes
@spacing = 16px;

@borderradius: 10px;

// Font sizes
@titlefontsize: 24px;
@titlefontsizesmall: 20px;

@fontsize: 16px;
@fontsizesmall: 14px;

@subtextfontsize: 12px;
@subtextfontsizesmall: 10px;
