.downlaod-app-link-container {
  color: #414146;
  margin-bottom: 100px;
  .mobile-apps {
    width: 100%;
    max-width: 400px !important;
  }
  .image-container {
    text-align: center;
  }

  .title {
    margin-bottom: 10px;
    font-weight: 700;
  }
  .subtitle {
    font-weight: 200;
  }
}
