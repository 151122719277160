.feature-info-container {
  padding: 50px;
  height: auto;
  min-height: 200px;
  margin: 0 auto;
  .image-container {
    padding: 0% 3% 3% 4%;
    text-align: center;
    display: flex;
    align-items: center;
    .image {
      width: 60%;
    }
  }

  .title {
    color: #414146;
    text-align: left;
    font-weight: 700;
    margin: 0 0 30px 0;
  }
  .subtitle {
    margin: 0px;
    font-size: 16px;
  }
}

@media (max-width: 801px) {
  .feature-info-container {
    padding: 20px;
  }
}
