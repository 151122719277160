@import "styles/colors.less";

.app-footer-container {
  height: 80px;
  background: @brand;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xxx-large;
  bottom: 0;
  position: fixed;
  width: 100%;
  .logo{
    width: 50px;
  }
}
